import React from "react";
import { SocialMedia, SidebarData } from "./SidebarData";

function Projects() {
  return (
    <React.Fragment>
      <div className="d-none d-lg-block">
        <LargeScreen />
      </div>
      <div className="d-block d-lg-none">
        <SmallScreen />
      </div>
    </React.Fragment>
  );
}

function SmallScreen() {
  return (
    <div style={{ backgroundColor: "#0b2138" }}>
      <div className="container">
        <div className="row jusify-content-center">
          <div className="text-center  fixed-top">
            <div style={{ backgroundColor: "#0b2138" }}>
              <Menu inline={true} />
            </div>
            <div className="text-center"></div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div
            className="offset-1 col-10"
            style={{
              color: "white",
              paddingTop: "40%",
              textAlign: "justify",
              textJustify: "inter-word",
            }}
          >
            <ProjectsContent />
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row jusify-content-center">
          <div className="text-center">
            <Social />
          </div>
        </div>
      </div>
    </div>
  );
}

function LargeScreen() {
  return (
    <React.Fragment>
      <div style={bodyStyle}>
        <div className="container m-0">
          <div className="row">
            <div className="offset-lg-1 col-md-1" style={menuStyle}>
              <div></div>
              <div>
                <Menu />
              </div>
              <div></div>
            </div>
            <div
              className="offset-md-2 offset-lg-1  col-md-8 col-lg-9"
              style={aboutContentStyle}
            >
              <div></div>
              <div>
                <ProjectsContent scrl={true} />
              </div>
              <div></div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row jusify-content-center">
            <div className="text-center fixed-bottom">
              <Social />
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

function Menu(props) {
  return (
    <div>
      {SidebarData.map((item, index) => {
        return (
          <div
            key={index}
            className={props.inline ? listInlineItem : "" + item.cName}
            style={menuListStyle}
          >
            <div style={{ padding: "6px" }}>
              <a
                href={item.path}
                style={
                  item.path === "/projects"
                    ? { textDecoration: "none", color: "#927807" }
                    : menuLinkStyle
                }
              >
                {item.title}
              </a>
            </div>
          </div>
        );
      })}
    </div>
  );
}
function Social() {
  return (
    <div>
      {SocialMedia.map((item, index) => {
        return (
          <div
            key={index}
            className={listInlineItem + item.cName}
            style={socialListStyle}
          >
            <a href={item.path} style={menuLinkStyle}>
              {item.icon}
            </a>
          </div>
        );
      })}
    </div>
  );
}

function ProjectsContent(props) {
  return (
    <React.Fragment>
      <div className="scrl" style={props.scrl ? scrollStyle : {}}>
        <p style={{ marginRight: "10px" }}>
          <h4 style={dec}>Papers</h4>{" "}
          <p>
            Title<span style={dec}>:</span>
          </p>
          <p>
          Robust DoA Estimation in a Uniform Circular Array Antenna with Errors and Unknown Parameters Using Deep Learning
           <br></br><span style={dec}>(</span>DOI <span style={dec}>:</span>
            <a style={dec} href="https://ieeexplore.ieee.org/abstract/document/10178088">10.1109/TGCN.2023.3294448</a>
            <span style={dec}>)</span>
          </p>
          <p>
            Abstract<span style={dec}>:</span>
          </p>
          <p>
          With the considerable rise in the number of devices connecting to the Internet
          <span style={dec}>,</span> the demand for an extensive capacity communication networks
           is already apparent<span style={dec}>.</span> Beam Division Multiple Access 
           <span style={dec}> </span><span style={dec}>(</span>BDMA<span style={dec}>)</span> is a 
           communication system method aimed at increasing capacity by simultaneously adapting portions 
           of the antenna beam to the locations of multiple Mobile Stations <span style={dec}>(</span>MSs<span style={dec}>)</span> 
           <span style={dec}> </span>achieve enabling more efficient and effective data transmission<span style={dec}>.</span> 
           <span style={dec}> </span>However<span style={dec}>,</span> to assess the best performance<span style={dec}>,</span> 
           <span style={dec}> </span>the Base Station <span style={dec}>(</span>BS<span style={dec}>)</span> must calculate 
           the exact location of the MSs<span style={dec}>.</span> In this research<span style={dec}>,</span> we propose the 
           Direction of Arrival <span style={dec}>(</span>DoA<span style={dec}>)</span> approach for locating 
           MSs<span style={dec}>,</span> where to determine the DoA<span style={dec}>,</span> an array of antennas is 
           utilized with two common configurations<span style={dec}>,</span> Uniform Linear Array <span style={dec}>(</span>ULA<span style={dec}>)</span> 
           <span style={dec}> </span>and Uniform Circular Array <span style={dec}>(</span>UCA<span style={dec}>).</span> We suggest UCA 
           because the main advantage of the circular array is the 360<span style={dec}>-</span>degree coverage<span style={dec}>.</span> 
           <span style={dec}> </span>Lately<span style={dec}>,</span> due to the growth of Neural Networks <span style={dec}>(</span>NNs<span style={dec}>),</span> 
           <span style={dec}> </span>many researchers have studied the estimation of DoA using NNs<span style={dec}>.</span> 
           <span style={dec}> </span>However<span style={dec}>,</span> these studies are not widely accepted in practice 
           due to the effect of series errors such as mutual coupling<span style={dec}>,</span> gain<span style={dec}>,</span> phase and element 
           position errors and unknown parameters such as the number of input signals and signal level difference<span style={dec}>.</span> This paper 
           proposes a Deep Neural Network <span style={dec}>(</span>DNN<span style={dec}>)</span> framework for estimating the 
           DoA in UCAs<span style={dec}>.</span> Transfer Learning and Multi<span style={dec}>-</span>Task techniques eliminate the need to count the 
           number of input signals<span style={dec}>,</span> reduce errors and bottleneck of NNs make the approach less sensitive than previous techniques<span style={dec}>.</span>
          </p>{" "}
          <p>
            Title<span style={dec}>:</span>
          </p>
          <p>
            Evaluating which pre<span style={dec}>-</span>trained model is
            suitable for which data by utilizing Separation Index method{" "}
            <span style={dec}>(</span>in progress<span style={dec}>)</span>
          </p>
          <p>
            {" "}
            Abstract<span style={dec}>:</span>
          </p>
          <p>
            In this article<span style={dec}>,</span> we attempt to evaluate the
            many parameters of the learning process using the Separation Index
            technique<span style={dec}>.</span> In this technique
            <span style={dec}>,</span> we can train pre
            <span style={dec}>-</span>trained models like VGG16
            <span style={dec}>,</span> VGG19 ResNet50 and MobileNet
            <span style={dec}>,</span> with Cifar10 and Cifar100 data in the
            transfer learning method<span style={dec}>.</span> Each of these
            pre<span style={dec}>-</span>trained models is considered as a
            filter<span style={dec}>.</span> With SI<span style={dec}>,</span>{" "}
            we keep track of the training<span style={dec}>.</span> The goal of
            this article is to determine the best and fastest model for specific
            data filtering<span style={dec}>,</span> i<span style={dec}>.</span>
            e<span style={dec}>.</span>
            <span style={dec}>,</span> which filter is appropriate for which
            data<span style={dec}>.</span> To be able to find which model in
            which layer has the largest data separation
            <span style={dec}>.</span> We<span style={dec}>'</span>ll notice
            that the largest separation distance<span style={dec}>,</span>
            as expected<span style={dec}>,</span> yielded the best
            classification<span style={dec}>.</span>
          </p>
          <h4 style={dec}>Projects</h4>
          <p>FPGA</p>{" "}
          <ul>
            <li>
              Designed and implemented accurate digital clock with Quartus and
              verilog
            </li>
            <li>
              Designed and implemented simple CPU with Quartus and verilog
            </li>
            <li>Designed and implemented FFT with Quartus and verilog</li>
          </ul>
          <p>AVR</p>
          <ul>
            <li> Implementation of light intensity measurement circuit</li>
            <li>
              Designed USART connection with PC to control and drive Stepper
              motor with Labview
            </li>
            <li>
              Designed Low frequency oscilloscope More than 40 practices and
              projects in Matlab and Simulink
            </li>
          </ul>
          <p>RF and Microwave devices</p>
          <p>
            I have extensive experiences in design and manufacturing passive
            elements such as Waveguides
            <span style={dec}>,</span> dividers<span style={dec}>,</span> horn
            <span style={dec}>, </span>
            LPDA<span style={dec}>,</span> conical<span style={dec}>,</span> bi
            <span style={dec}>-</span>conical<span style={dec}>,</span>{" "}
            microstrips etc<span style={dec}>.</span> My major is to calculate
            and setup different type of arrays<span style={dec}>.</span> I
            obtained valuable knowledge in this industry by creating more than
            40 items<span style={dec}>.</span> I have extensive knowledge of
            waveguides and waveguide antennas<span style={dec}>,</span>{" "}
            including horn antennas<span style={dec}>,</span> leaky wave
            antennas<span style={dec}>,</span> and other waveguide antennas
            <span style={dec}>,</span> as well as wired and microstrip antennas
            <span style={dec}>.</span> It is also worth mentioning that I've
            worked with a variety of various types of arrangements
            <span style={dec}>,</span> both singular and in arrays
            <span style={dec}>.</span>
          </p>{" "}
          <p>AI experiences</p>
          <p>
            <ul>
              <li>
                {" "}
                Text classification and Text generations with RNN
                <span style={dec}>,</span> LSTM<span style={dec}>,</span> GRU
                <span style={dec}>,</span> etc<span style={dec}>.</span>
              </li>
              <li>
                Multi<span style={dec}>-</span>Task classification to detect
                various product of e<span style={dec}>-</span>commerce business
              </li>
              <li>Generate artificial faces with different kind of GANs</li>
            </ul>
          </p>
          <p>
            These actions are<span style={dec}>,</span> without a doubt
            <span style={dec}>,</span> a modest part of my overall work in these
            areas<span style={dec}>.</span> I am unable to discuss my industrial
            actions in these areas due to copyright and ethical concerns
            <span style={dec}>,</span> and just a portion of the exercises have
            been discussed<span style={dec}>.</span> If you
            <span style={dec}>'</span>re interested in my field of work
            <span style={dec}>,</span> you may reach out to me via email at{" "}
            <a style={dec} href="mailto: navidlabbaf94@gmail.com">
              navidlabbaf94@gmail.com
            </a>
            <span style={dec}>.</span> The last item I<span style={dec}>'</span>
            m looking into is a novel approach to cluster data in order to
            separate it more effectively<span style={dec}>.</span>
          </p>
        </p>
      </div>
    </React.Fragment>
  );
}

const listInlineItem = "list-inline-item ";

const bodyStyle = {
  backgroundColor: "#0b2138",
  height: "100vh",
  fontFamily: "arial",
};
const menuStyle = {
  display: "grid",
  gridTemplateRows: "16vh 33vh auto",
  color: "white",
};
const aboutContentStyle = {
  display: "grid",
  gridTemplateRows: "16vh 33vh auto",
  color: "white",
  fontSize: "12px",
  textAlign: "justify",
  textJustify: "inter-word",
};

const menuListStyle = {
  listStyle: "none",
  textDecoration: "none",
};
const menuLinkStyle = {
  textDecoration: "none",
  color: "#fbca04",
};
const socialListStyle = {
  listStyle: "none",
  textDecoration: "none",
  padding: "20px",
};
const dec = {
  color: "#fbca04",
  textDecoration: "none",
};
const scrollStyle = {
  height: "70vh",
  width: "100%",
  overflow: "auto",
};
export default Projects;
